<template>
  <SvgIcon>
    <ClockSvg :icon-color="iconColor"></ClockSvg>
  </SvgIcon>
</template>

<script>

import ClockSvg from "@/components/icons/partials/ClockSvg"
import SvgIcon from "@/components/icons/SvgIcon";

export default {
  components: {SvgIcon, ClockSvg},
  props: {
    iconColor: {
      type: String,
      default: 'rgb(35.688782%, 68.238831%, 85.488892%)'
    }
  }
}

</script>