import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import ApiData from '@/ApiData';
import Explore from "@/components/Explore";
import Backsides from "@/components/Backsides";
import AccessibilityNotice from "@/components/AccessibilityNotice";
import 'keen-ui/src/bootstrap';

Vue.config.productionTip = false

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        apiData: ApiData
    }
})

const routes = [
    {path: '/', component: Explore, name: 'allTiles'},
    {path: '/tile/:tileId', component: Explore, props: true, name: 'tile'},
    {path: '/backsides', component: Backsides},
    {path: '/accessibility', component: AccessibilityNotice,
        props: {
            siteURL: "https://stress-tools.digitalhealth.tu-dresden.de",
            creationDate: "10.11.2021",
            modificationDate: "10.11.2021",
            evalStartDate: "01.10.2021",
            evalEndDate: "10.11.2021"
        }
    }
]

const router = new VueRouter({
    routes
})

Vue.use(VueRouter)

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
