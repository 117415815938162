<template>
  <div class="filters">
    <slot name="extra-above"></slot>

    <div class="filter-group">
      <MultiSelectConstructs
          :allConstructs="allConstructs"
          v-model="selected.constructs"/>
      <div class="help">
        <template v-if="selected.constructs.length == 0">
          Select a construct to confine your search.
        </template>
        <template v-else>
          Only tiles matching at least <i>one</i> selected construct are shown.
        </template>
      </div>
    </div>

    <div class="filter-group">
      <ui-checkbox-group
          vertical
          box-position="right"
          v-model="selected.researchMethods"
          :options="allResearchMethods"
          :keys="{label:'name', value:'id'}"
          help="Select research types that might be relevant for you"
      >
      </ui-checkbox-group>
    </div>

    <div class="filter-group">
      <ui-checkbox-group
          vertical
          box-position="right"
          v-model="selected.languages"
          :options="allLanguages"
          :keys="{label:'name', value:'id'}"
          help="Select languages that must be supported"
      >
      </ui-checkbox-group>
    </div>

    <div class="filter-group">
      <ui-checkbox
          box-position="right"
          v-model="selected.requireNormGroup"
      >
        Norm Group
      </ui-checkbox>
    </div>

    <div class="filter-group">
      <div class="row">
        <div class="left">Duration</div>
        <div class="right">
          <IconScale :val="selected.maxLength" @change="selected.maxLength = $event" :interactive="true"
                     :tooltips="durationCategories" :max="durationCategories.length - 1">
            <template slot="active">
              <Clock icon-color="#555555"></Clock>
            </template>
            <template slot="inactive">
              <Clock icon-color="#cccccc"></Clock>
            </template>
          </IconScale>
        </div>
      </div>
    </div>

    <div class="filter-group">
      <div class="row">
        <div class="left">Expertise</div>
        <div class="right">
          <IconScale :val="selected.maxExpertize" @change="selected.maxExpertize = $event" :interactive="true">
            <template v-slot:active>
              <Hat icon-color="#555555"></Hat>
            </template>
            <template v-slot:inactive>
              <Hat icon-color="#cccccc"></Hat>
            </template>
          </IconScale>
        </div>
      </div>
    </div>

    <div class="filter-group">
      <label for="slider">Minimum Reliability</label>
      <ui-slider id="slider"
                 :min="4"
                 :max="9"
                 :step="1"
                 :snapToSteps="true"
                 :showMarker="true"
                 :markerValue="sliderLabel"
                 v-model="sliderValue"
      ></ui-slider>
    </div>

    <div class="filter-group">
      <ui-checkbox
          v-model="selected.requireStudyOnDH"
          box-position="right"
          title="Study on digitalization and health exists"
          >
        Study on D&H
      </ui-checkbox>
      <div class="help only-mobile">
        Click to only show instruments with studies regarding application to digitalization and health.
      </div>
    </div>
  </div>
</template>
<script>

import UiCheckboxGroup from 'keen-ui/lib/UiCheckboxGroup';
import UiCheckbox from 'keen-ui/lib/UiCheckbox';
import UiSlider from 'keen-ui/lib/UiSlider';
import languageMap from '@/Languages';
import IconScale from "@/components/IconScale";
import Hat from "@/components/icons/Hat";
import Clock from "@/components/icons/Clock";
import MultiSelectConstructs from "@/components/MultiSelectConstructs";
import {mapGetters} from "vuex";

export default {
  name: 'TBFilters',
  components: {MultiSelectConstructs, UiCheckboxGroup, UiCheckbox, UiSlider, IconScale, Hat, Clock},
  props: {
    selected: Object,
  },
  computed: {
    ...mapGetters({
      allQuestionnaires: 'apiData/allQuestionnaires',
      allResearchMethods: 'apiData/allResearchMethods',
      allConstructs: 'apiData/allConstructs',
      durationCategories: 'apiData/durationCategories'
    }),
    allLanguages(self) {
      return Object.entries(languageMap).map(it => {
        return {"id": it[0], "name": it[1]}
      });
    },
    sliderValue: {
      get(self) {
        if (this.selected.minReliability === 0) {
          return 4
        } else {
          return this.selected.minReliability * 10;
        }
      },
      set(val) {
        // slider goes from 4 to 9
        // 4 means 0, 5 means 0.5, ..., 9 means 0.9
        if (val === 4) {
          this.selected.minReliability = 0;
        } else {
          this.selected.minReliability = val / 10;
        }
      }
    },
    sliderLabel(self) {
      if (this.selected.minReliability === 0) {
        return "any";
      } else {
        return '≥' + this.selected.minReliability;
      }
    }
  }
}
</script>
<style scoped>

.filters {
  user-select: none;
}

.help {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  line-height: 1.2;
}

.filters > .help {
  margin-top: 1em;
  color: #dddddd;
}

.filter-group {
  background-color: white;
  margin: 1em;
  padding: 1em;
  text-align: left;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.row div {
  align-self: center;
}

.left {
  justify-self: left
}

label {
  margin-left: 0;
}

.right {
  justify-self: right;
}

.right .icon-group {
  margin-right: 0
}

</style>
