<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       :width="width"
       zoomAndPan="magnify"
       viewBox="0 0 320 320"
       :height="height"
       preserveAspectRatio="xMidYMid meet"
       version="1.0"
  >
    <slot></slot>
  </svg>
</template>
<script>

export default {
  name: 'SvgIcon',
  components: {},
  props: {
    width: {
      type: [Number, String],
      default: "2em"
    },
    height: {
      type: [Number, String],
      default: "2em"
    }
  }
}
</script>