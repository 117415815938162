<template>
  <div class="icon-group">
    <div :class="{interactive}" v-for="i in val" v-bind:key="'act'+i"
         @click="interactive && $emit('change', i === val ?  0: i)"
         :title="i < tooltips.length ? tooltips[i] : null">
      <slot name="active">
      </slot>
    </div>
    <div :class="{interactive}" v-for="i in (max-val)" v-bind:key="'ina'+i"
         @click="interactive && $emit('change', i+val)"
         :title="i+val < tooltips.length ? tooltips[i+val] : null">
      <slot name="inactive"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconScale',
  props: {
    val: Number,
    max: {
      type: Number,
      default: 3
    },
    interactive: {
      type: Boolean,
      default: false
    },
    tooltips: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style>

.icon-group img {
  height: 2em;
  user-drag: none; /* not working in Firefox */
  user-select: none;
  pointer-events: none;
}

.icon-group div {
  display: inline-block;
}

.icon-group {
  margin-right: 1em;
  display: inline-block;
}

.interactive {
  cursor: pointer;
}
</style>