<template>
  <SvgIcon>
    <HatSvg :icon-color="iconColor"/>
  </SvgIcon>
</template>

<script>

import HatSvg from "@/components/icons/partials/HatSvg";
import SvgIcon from "@/components/icons/SvgIcon";

export default {
  components: {SvgIcon, HatSvg},
  props: {
    iconColor: {
      type: String,
      default: 'rgb(35.688782%, 68.238831%, 85.488892%)'
    }
  }
}

</script>