<template>
  <div class="large-tile" :style="tileStyle">
    <div class="shortVersion">{{ item.longVersion }} <span class="abbreviation" v-if="item.abbreviation">({{ item.abbreviation }})</span></div>
    <div class="icons">
      <IconScale :val="item.length">
        <template v-slot:active>
          <Clock :icon-color="textColor"></Clock>
        </template>
        <template v-slot:inactive>
          <Clock :icon-color="lightColor"></Clock>
        </template>
      </IconScale>
      <IconScale :val="item.expertise">
        <template v-slot:active>
          <Hat :icon-color="textColor"></Hat>
        </template>
        <template v-slot:inactive>
          <Hat :icon-color="lightColor"></Hat>
        </template>
      </IconScale>
    </div>
    <LargeStain :item="item"/>
    <div class="authors">
      <span class="heading">Authors:</span>&#32;
      <span class="authors-authors">{{authors}}</span>
    </div>
    <div class="subScales" :style="{visibility: item.subscales.length > 0 ? 'inherit' : 'hidden'}">
      <span class="heading">{{ item.numSubscales }} subscale{{ item.numSubscales === 1 ? '' : 's' }}:</span>
      &#32;
      <span>
        <JoinedList :list="displayedSubscales" sep=", "></JoinedList>
      </span>
    </div>
    <div class="example" :style="{visibility: item.example ? 'inherit' : 'hidden'}">
      <span class="heading">Item:</span>
      "{{ item.example }}"
    </div>
    <ShowRefs :item="item"/>

  </div>
</template>
<script>
import IconScale from "@/components/IconScale";
import LargeStain from "@/components/LargeStain";
import ShowRefs from "@/components/ShowRefs";
import JoinedList from "@/components/JoinedList";
import Clock from "@/components/icons/Clock";
import Hat from "@/components/icons/Hat";
import {mapGetters} from "vuex";

export default {
  name: 'LargeTile',
  components: {ShowRefs, LargeStain, IconScale, JoinedList, Clock, Hat},
  props: {
    item: {}
  },
  computed: {
    ...mapGetters({
      findConstruct: 'apiData/findConstruct'
    }),
    authors(self) {
      const originalRef = self.item.refs.find(ref => ref.type === "original");
      if (originalRef) {
        const refStr = originalRef.text;
        const until = refStr.indexOf(")");
        let authorStr = refStr.substring(0, until + 1);
        authorStr = authorStr.replaceAll(/\s+\,/g, ",");
        authorStr = authorStr.replaceAll(/\,(\s+[A-Z]\.)+/g,"");
        authorStr = authorStr.replaceAll(/\,\s+\&/g, " &");
        return authorStr;
      } else {
        return "";
      }
    },
    tileStyle(self) {
      return {color: self.textColor}
    },
    textColor(self) {
      return this.findConstruct(self.item.construct_keys[0]).color;
    },
    lightColor(self) {
      return self.increase_brightness(self.textColor, 70);
    },
    displayedSubscales(self) {
      const list = self.item.subscales.slice();
      const itemsMissing = list.length < self.item.numSubscales;
      if (itemsMissing) {
        list.push("and others");
      }
      return list;
    }
  },
  methods: {
    increase_brightness(hex, percent){ // https://stackoverflow.com/a/6444043/2575414
      // strip the leading # if it's there
      hex = hex.replace(/^\s*#|\s*$/g, '');

      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if(hex.length == 3){
        hex = hex.replace(/(.)/g, '$1$1');
      }

      var r = parseInt(hex.substr(0, 2), 16),
          g = parseInt(hex.substr(2, 2), 16),
          b = parseInt(hex.substr(4, 2), 16);

      return '#' +
          ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
          ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
          ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
    }
  }
}
</script>
<style>
.large-tile {
  border: 2px solid gray;
  padding: 2em;
  text-align: left;
  color: #444499;
  margin: 20px;
  overflow: hidden;
  background-color: white;
}

.shortVersion {
  font-size: 2em;
}

.longVersion {
  font-weight: bold;
}

.icons {
  min-height: 2em;
  max-height: 2em;
  overflow: hidden;
  margin-bottom: 0.5em;
}

.authors, .subScales, .example {
  margin-top: 1em;
}

.authors-authors {
  font-weight: bold;
}

.large-tile {
  max-width: 50em;
  margin: auto; /* todo not necessary */
}

.refs {
  margin-top: 1em;
}

.heading {
  text-decoration: underline;
}

</style>