<template>
  <div class="stain">
    <!-- stain must be a background image to make pirating less straight-forward-->
    <div class="stain-background" :style="'background-image: url(' + imageSource + ')'"></div>
    <ul class="fg">
      <li>{{ item.numberItems }} {{ item.numberItems == 1 ? 'item' : 'items' }}</li>
      <li>{{ item.answeringScale }}</li>
      <li>
        <span v-if="item.normGroup">norm group exists</span>
        <span v-else>no norm group</span>
      </li>
      <li v-if="item.cronbachAlpha">Cronbach's Alpha: {{ item.cronbachAlpha }}</li>
      <li>
        <JoinedList :list="item.languages" v-slot="{item}" :sep="' & '">
          <ShowLanguage :language="item"/>
        </JoinedList>
      </li>
    </ul>
  </div>
</template>
<script>
import JoinedList from "@/components/JoinedList";
import ShowLanguage from "@/components/ShowLanguage";

export default {
  name: 'LargeStain',
  components: {JoinedList, ShowLanguage},
  props: {
    item: {}
  },
  computed: {
    allCategories: (self) => self.item.construct_keys.sort().join('&'),
    imageSource: (self) => "/stains/" + self.allCategories + ".svg.png",
  }
}
</script>
<style>

@media screen and (min-width: 650px) {
  .stain {
    float: right;
  }
}
@media screen and (max-width: 649px) {
  .stain {
    float: none;
    margin: 2.6em auto 4em;
  }
  .stain-background {
    background-size: 40% !important;
  }
}

.stain {
  position: relative;
  max-width: 15em;
}

.stain li {
  margin-top: 0.2em;
}

.stain-background {
  opacity: 0.5;
  position: absolute;
  top: -80%;
  left: -80%;
  right: -80%;
  bottom: -80%;
  z-index: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.fg {
  z-index: 3;
  position: relative;
}

</style>