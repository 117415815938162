<template>
  <div>
    <template v-for="c in allConstructs">
      <ui-checkbox box-position="right"
                   :key="c.id" :value="isSelected(c)"
                   @change="toggleConstruct(c)">
        {{ c.name }}
      </ui-checkbox>
      <template v-if="value.indexOf(c.id) !== -1">
        <template v-for="d in c.children">
          <ui-checkbox class="nested" box-position="right" :key="d.id"
                       :value="isSelected(d)"
                       @change="toggleConstruct(d)">{{ d.name }}
          </ui-checkbox>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import UiCheckbox from 'keen-ui/lib/UiCheckbox';

export default {
  components: {UiCheckbox},
  name: 'MultiSelectConstructs',
  props: {
    allConstructs: Array,
    value: Array
  },
  methods: {
    toggleConstruct(construct) {
      let newValue = null;
      const id = construct.id;
      const childrenIds = construct.children.map(c => c.id);
      if (this.isSelected(construct)) {
        this.value.splice(this.value.indexOf(id), 1);
        newValue = this.value.filter(it => it !== id && !childrenIds.includes(it));
      } else {
        newValue = [...this.value, id]
        childrenIds.forEach(cid => newValue.push(cid));
      }
      this.$emit('input', newValue)
    },
    isSelected(construct) {
      return this.value.indexOf(construct.id) !== -1;
    }
  }
}
</script>
<style scoped>

.row div {
  align-self: center;
}

label {
  margin-left: 0;
}

.nested {
  margin-left: 1em;
}

</style>