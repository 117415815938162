<template>
  <transition-group
      name="tile-list"
      tag="div"
      class="tiles"
      :duration="2000"
      @before-leave="beforeLeave"
  >
    <div class="tile" v-for="q in tiles" :key="q.nr"
         @click="$router.push({ name: 'tile', params: { tileId: q.nr } })">
      <img :src="'tiles/FB_' + q.nr + '.svg.convert.png'" :alt="q.shortVersion" width="100%">
    </div>
    <div class="no-tiles" :key="'no-tiles'" v-if="tiles.length === 0">
      There is no instrument matching your criteria :&#xfeff;(
    </div>
  </transition-group>
</template>
<script>
export default {
  name: 'TileGrid',
  props: {
    tiles: Array
  },
  methods: {
    beforeLeave(el) {
      const {marginLeft, marginTop, width, height} = window.getComputedStyle(el)
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
      el.style.width = width
      el.style.height = height
      //el.style.position = 'absolute';
    }
  }
}
</script>
<style scoped>
.tiles {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, 10em);
  grid-template-rows: repeat(auto-fit, 10em);
  gap: 10px;
  grid-column-start: col2-start;
  justify-content: center;
}

@media screen and (max-width: 649px) {
  .tiles {
    grid-column: 1;
  }

  .no-tiles {
    display: none; /* There is another text at the top in this case */
  }
}

@media screen and (min-width: 650px) {
  .no-tiles {
    position: absolute;
    top: 40%;
    left: auto;
    right: auto;
  }
}


.tile {
  cursor: pointer;
}

img {
  pointer-events: none;
  user-select: none;
}

.tile {
  transition: all 1s;
}

.no-tiles {
  transition: opacity 0.5s;
  font-size: 2.5em;
  padding: 1em;
  color: gray;
}

label {
  margin-left: 0;
}

.tile-list-enter, .tile-list-leave-to {
  opacity: 0;
}

.tile-list-leave-active {
  position: absolute;
}

.tile.tile-list-enter, .tile.tile-list-leave-to {
  transform: translateY(1000px) translateX(50px) scale(0.2);
  opacity: 0;
}



</style>
