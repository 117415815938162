<template>
  <div class="refs">
    <span class="refs-heading">Sources:</span>
    <div class="ref" v-for="ref in item.refs" v-bind:key="ref.type">
      <a v-bind:href="ref.url" v-bind:title="humanType(ref.type)" target="_blank">
        <span class="refs-type">{{ humanType(ref.type) }}:</span>
        {{ ref.text }}
      </a>
    </div>
  </div>
</template>

<script>
const HUMAN_TYPES = {
  "original": "Original Publication",
  "validation": "Validation Study",
  "application": "Application",
  "normGroup": "Norm Sample"
}

export default {
  name: 'ShowRefs',
  props: {
    item: {}
  },
  methods: {
    humanType: (type) => {
      return HUMAN_TYPES[type] || type;
    }
  }
}
</script>
<style>

.refs {
  font-size: 0.6em;
}

.refs a {
  text-decoration: none;
  color: inherit;
}

.ref {
  margin-top: 0.1em;
}

.refs-heading {
  font-weight: bold;
}

.refs-type {
  text-decoration: underline;
}

</style>