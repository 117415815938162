<template>
  <div>
    <div class="explore">
      <div class="filter-column">
        <TBFilters :selected="selected">
          <div class="help only-mobile" slot="extra-above">
            <template v-if="filteredQuestionnaires.length > 0">Scroll down to view the tiles</template>
            <template v-else>Sorry, there are no tiles matching your selection criteria.</template>
          </div>
        </TBFilters>
      </div>
      <TileGrid :tiles="filteredQuestionnaires"/>
      <Overlay v-if="currentTile" @close="$router.push({ name: 'allTiles'})">
        <LargeTile :item="currentTile"></LargeTile>
      </Overlay>
    </div>
    <div class="footer">
      <a href="https://digitalhealth.tu-dresden.de/imprint/">Imprint</a>
      <router-link to="/accessibility">Accessibility</router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Overlay from "@/components/Overlay";
import LargeTile from "@/components/LargeTile";
import TileGrid from "@/components/TileGrid";
import TBFilters from "@/components/TBFilters";

export default {
  name: "Explore",
  components: {TBFilters, TileGrid, Overlay, LargeTile},
  data: (self) => {
    return {
      selected: {
        researchMethods: ['Interview', 'Questionnaire', 'Biomarker'],
        languages: [],
        requireNormGroup: false,
        minReliability: 0,
        requireStudyOnDH: false,
        maxExpertize: 3,
        maxLength: 3,
        constructs: []
      }
    };
  },
  props: {
    tileId: String
  },
  computed: {
    ...mapGetters({
      allQuestionnaires: 'apiData/allQuestionnaires',
      findQuestionnaire: 'apiData/findQuestionnaire'
    }),
    currentTile(self) {
      return this.findQuestionnaire(self.tileId);
    },
    filteredQuestionnaires(self) {
      return this.allQuestionnaires.filter(it => {
            const itsLangs = new Set(it.languages);
            return self.selected.researchMethods.includes(it.researchMethod) &&
                self.selected.languages.every(lang => itsLangs.has(lang)) &&
                (!self.selected.requireNormGroup || it.normGroup) &&
                (self.selected.minReliability === 0 || self.selected.minReliability <= it.reliability) &&
                (!self.selected.requireStudyOnDH || it.usedInResearch) &&
                (self.selected.maxLength >= it.length) &&
                (self.selected.maxExpertize >= it.expertise) &&
                (self.selected.constructs.length === 0 || self.selected.constructs.some(constr => it.construct_keys.includes(constr)))
                ;
          }
      )
    },
  }
}
</script>

<style scoped lang="scss">
@import '../style-vars.scss';

@media screen and (max-width: 649px) {
  .explore {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
  .filters {
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    min-height: 10em;
  }
}

@media screen and (min-width: 650px) {
  .explore {
    grid-template-columns: [col1-start] 20em [col2-start] 1fr;
  }
  .filters {
    top: 4em;
  }
  .filter-column {
    grid-column-start: col1-start;
    margin-right: 1em;
    min-height: 100em;
  }
}


.filter-column {
  background-color: $background;
  padding-bottom: 1em;
}

.explore {
  display: grid;
}

</style>

<style>
.ui-checkbox__checkmark-background { /* hack to restyle underlying checkbox */
  border-radius: 100% !important;
}
.ui-slider__marker {
  transform: scale(1.4) translateY(-1.8rem) !important;
}
.ui-slider__marker-text {
  font-size: 0.6125rem !important;
}
</style>