<template>
  <div class="backsides">
    <LargeTile v-for="item in questionnaires" v-bind:key="item.id" :item="item"/>
  </div>
</template>
<script>
import LargeTile from "@/components/LargeTile"
import { mapGetters } from 'vuex'

export default {
  name: 'Backsides',
  components: {LargeTile},
  computed: {...mapGetters({questionnaires: 'apiData/allQuestionnaires'})}
}
</script>
