import axios from "axios";

function makeConstrHierarchy(allElems) {
    const rootElems = allElems.filter(constr => constr.constructNumber === constr.categoryNumber)
    return rootElems.map(it => {
        const children = allElems.filter(child => child.categoryNumber === it.categoryNumber && child.constructNumber !== it.categoryNumber).map(child => {return {
            name: child.constructName, id: child.constructNumber, color: child.color, children: []}});
        return {name: it.categoryName, id: it.categoryNumber, color: it.color, children: children}
        }
    );
}

export default {
    namespaced: true,
    state: {
        constructs: [],
        questionnaires: [],
        researchMethods: [],
        durationCategories: []
    },
    getters: {
        allQuestionnaires: (state) => state.questionnaires,
        allResearchMethods: (state) => state.researchMethods,
        allConstructs: (state) => {
            return makeConstrHierarchy(state.constructs);
        },
        durationCategories: (state) => state.durationCategories,
        findQuestionnaire: (state, getters) => nr => {
            return getters.allQuestionnaires.find(it => it.nr == nr) // == because comparing string to int
        },
        findConstruct: (state, getters) => id => {
            function dfs(list) {
                for (const c of list) {
                    if (c.id == id) return c;
                    const subResult = dfs(c.children);
                    if (subResult) return subResult
                }
                return false;
            }
            return dfs(getters.allConstructs);
        }
    },
    mutations: {
        updateApiData(state, data) {
            state.constructs = data.constructs;
            state.questionnaires = data.questionnaires;
            state.researchMethods = data.researchMethods;
            state.durationCategories = data.durationCategories;
        }
    },
    actions: {
        async fetchApiData(context) {
            const response = await axios.get('/data.json');
            if (response && response.data) {
                context.commit('updateApiData', response.data);
            }
        }
    }
}