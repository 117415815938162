<template>
  <span>
    <span v-for="(item, i) in this.list" :key="i"><template v-if="i>0">{{ sep }}</template><slot :item="item">{{item}}</slot></span>
  </span>
</template>
<script>

export default {
  name: 'JoinedList',
  props: {
    list: Array,
    sep: String
  }
}
</script>
