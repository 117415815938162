<template>
  <div id="app">
    <div class="top">
      <h1>Stress Method Tool Box</h1>
      <h2><i>Standardized Tool Box for the assessment of the stress-physiological consequences of digitalization for
        medical
        practitioners</i></h2>
      <h2><i>by <a target="_blank" href="https://tu-dresden.de/mn/psychologie/ifap/biopsychologie/die-professur/beschaeftigte/magdalena-kanthak">Magdalena Wekenborg</a> funded by <a target="_blank" href="https://digitalhealth.tu-dresden.de/">EKFZ</a></i></h2>
    </div>
    <div class="v-space no-mobile"></div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: function () {
    return {
    };
  },
  async mounted() {
    this.$store.dispatch('apiData/fetchApiData');
  }
}
</script>

<style lang="scss">
@import 'style-vars.scss';

.footer {
  background-color: $background;
  width: 100%;
  padding: 0.5em;
}

.footer a {
  display: inline-block;
  margin-left: 1em;
  margin-right: 1em;
  color: white;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.top {
  background-color: $background;
  padding: 1em;
  margin-top: 0;
  color: white;
  text-align: left;
}

.top h1, .top h2, .top h3 {
  margin-top: 0;
  margin-block-end: 0.2em;
}

.top h2 {
  font-size: 1em;
}

.top a {
  text-decoration: none;
  color: $highlight;
  font-style: normal;
}

.top a:hover {
  text-decoration: underline;
}

.v-space {
margin-bottom: 1em;
}
body {
  margin: 0;
  overflow-x: hidden;
}
@media screen and (max-width: 649px) {
  .no-mobile {
    display: none;
  }
}
@media screen and (min-width: 650px) {
  .only-mobile {
    display: none;
  }
}
</style>
