<template>
  <div>
    <div class="accessibility-notice">
      <h1>Erklärung zur Barrierefreiheit</h1>
      <p>
        Diese Erklärung zur Barrierefreiheit gilt für die unter <a :href="siteURL">{{ siteURL }}</a> veröffentlichte
        Website.
      </p>
      <p>
        Das EKFZ ist bemüht, seine Websites und mobilen Anwendungen im Einklang mit den Bestimmungen des
        Barrierefreie-Websites-Gesetz (BfWebG) sowie in Verbindung mit der Barrierefreie-Informationstechnik-Verordnung
        (BITV 2.0) barrierefrei zugänglich zu machen.
      </p>
      <h2>Erstellung dieser Erklärung zur Barrierefreiheit</h2>
      <p>Diese Erklärung wurde am {{creationDate}} erstellt und zuletzt am {{modificationDate}} aktualisiert.</p>
      <p>Grundlage der Erstellung dieser Erklärung zur Barrierefreiheit ist eine im Zeitraum vom {{evalStartDate}} bis
        {{evalEndDate}} von der Einrichtung durchgeführte (BITV-)Selbstbewertung.</p>
      <h2>Stand der Vereinbarkeit mit den Anforderungen der BITV</h2>
      <p>Diese Website ist wegen der folgenden Unvereinbarkeiten und/oder Ausnahmen teilweise mit den Vorgaben des
        BfWebG in Verbindung mit der BITV 2.0 vereinbar.</p>
      <h2>Nicht barrierefreie Inhalte</h2>
      <ul>
        <li>Teilweise sind Alternativen für Bilder und grafische Elemente nicht in vollem Umfang vorhanden.</li>
        <li>ARIA-Attribute zur Orientierung innerhalb von Elementen sind nicht in vollem Umfang vorhanden.</li>
        <li>Die Position des Tastaturfokus ist nicht immer klar ersichtlich.</li>
        <li>Eine Erklärung in leichter Sprache wird noch implementiert.</li>
      </ul>
      <h2>Kontakt</h2>
      <p>
        Sollten Ihnen Mängel in Bezug auf die barrierefreie Gestaltung auffallen, wenden Sie sich bitte per E-Mail an <a
          href="mailto:ekfz@tu-dresden.de">ekfz@tu-dresden.de</a>.
      </p>
      <h2>Durchsetzungsverfahren</h2>
      <p>
        Wenn wir Ihre Rückmeldungen aus Ihrer Sicht nicht befriedigend bearbeiten, können Sie sich an die <em>Sächsische
        Durchsetzungsstelle</em> wenden:
      </p>
      <p>
        Beauftragter der Sächsischen Staatsregierung für die Belange von Menschen mit Behinderungen<br>
        Albertstraße 10,
        01097 Dresden
      </p>
      <p>
        Postanschrift: Archivstraße 1, 01097 Dresden<br>
        E-Mail: <a href="mailto:info.behindertenbeauftragter@sk.sachsen.de"><u>info.behindertenbeauftragter@sk.sachsen.de</u></a><br>
        Telefon: <a href="tel:035156412161"><u>0351 564-12161</u></a><br>
        Fax: 0351 564-12169<br>
        Webseite: <a href="https://www.inklusion.sachsen.de"><u>https://www.inklusion.sachsen.de</u></a>
      </p>
    </div>
    <div class="footer">
      <router-link to="/">Back</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessibilityNotice",
  props: {
    siteURL: {
      type: String,
      required: true
    },
    creationDate: {
      type: String,
      required: true
    },
    modificationDate: {
      type: String,
      required: true
    },
    evalStartDate: {
      type: String,
      required: true
    },
    evalEndDate: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped lang="scss">
@import "../style-vars";

.accessibility-notice {
  text-align: left;
  padding: 2em;
}

h1 {
  color: $heading;
}

h2 {
  color: $heading;
}

li {
  text-align: -webkit-match-parent;
}

ul {
  list-style-type: square;
}
</style>