<template>
  <span>{{ display }}</span>
</template>
<script>
import languageMap from '@/Languages'

export default {
  name: 'ShowLanguage',
  props: {
    language: String
  },
  computed: {
    display: function() {
      return languageMap[this.language];
    }
  }
}
</script>