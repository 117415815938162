<template>
  <div class="overlay" @click="handleBackgroundClick($event)">
    <div class="overlay-inner">
      <slot></slot>
      <UiCloseButton class="close-button" @click="close">Close</UiCloseButton>
    </div>
  </div>
</template>

<script>
import UiCloseButton from 'keen-ui/lib/UiCloseButton'

export default {
  name: "Overlay",
  components: {UiCloseButton},
  methods: {
    handleBackgroundClick(event) {
      if (event.target === this.$el) { /* click did not hit a child */
        this.close();
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlay-inner {
  position: relative;
}

.close-button {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 4;
}

@media screen and (max-width: 649px) {
  .overlay {
    align-items: flex-start;
  }
}
</style>